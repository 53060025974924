import React, { Component } from "react";
import { Col, Form, Button, Row, Alert } from "react-bootstrap";
import {apiUrl} from "../../config.json";
import InputDataForm from "../../components/common/form";
import httpService from "../../services/common/httpService";
import { useParams } from "react-router-dom";
import MegaNavBar from "../../components/common/megaNavBar";
import { withRouter } from "react-router-dom";
class InvoiceFinancing extends InputDataForm {
  state = {
    data: {
      firstName: "",
      lastName: "",
      mobileNo: "",
      email: "",
    },
    errors: {},
    successMessage: "",
    partnerId :""
  };
  

  componentDidMount() {
    // Extract partnerId from URL using props (provided by withRouter)
    const { match } = this.props;
    const partnerId = match.params.partnerId;
    if (partnerId) {
      this.setState({ partnerId });
    }
  }
  handleChange = ({ target: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
  
    this.setState({ data });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { data } = this.state;
      data['partnerId'] = this.state.partnerId

      // API call
      const response = await httpService.post(apiUrl + "/ops/users/invoiceFinanceUserRegister", data);

      // Handle success
      if (response.status === 201) {
        this.setState({
          successMessage: "Successfully registered. Redirecting to more details...",
        });
        setTimeout(() => {
          window.open(
            "https://app.upscale.cash/?utm_campaign=DIF&utm_term=UpScale&utm_id=FundFina&utm_medium=DigitalDSA",
            "_blank"
          );
        }, 3000);
      } else {
        alert("There was an issue with your registration. Please try again.");
      }
    } catch (error) {
      // Check if the error has a response and message
  if (error.response && error.response.data && error.response.data.message) {
    alert(error.response.data.message); // Show the API message in an alert
  } else {
    // Fallback error message
    alert("An error occurred while submitting the form. Please check your inputs and try again.");
  }
    }
  };

  render() {
    // const params = useParams();
    const { data, successMessage } = this.state;
    // const partnerId = params.partnerId;
    

    return (
      <>
      <MegaNavBar/>
      <Form onSubmit={this.handleSubmit} className="p-3">
        <h2>MerchantBikri - Invoice Financing</h2>
        <div className="pt-4">
          {successMessage && (
            <Alert variant="success" className="text-center">
              {successMessage}
            </Alert>
          )}
          <React.Fragment>
            <Form.Row>
              <Form.Group as={Col} md="6" xs="12" controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  name="firstName"
                  value={data["firstName"]}
                  onChange={this.handleChange}
                  autoFocus={true}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} md="6" xs="12" controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  name="lastName"
                  value={data["lastName"]}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" xs="12" controlId="mobileNo">
                <Form.Label>Mobile No.</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your mobile no."
                  name="mobileNo"
                  value={data["mobileNo"]}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} md="6" xs="12" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email Id"
                  name="email"
                  value={data["email"]}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
            </Form.Row>

            <Row className="mt-3">
              <Col xs="12" className="text-center">
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        </div>
      </Form>
      </>
    );
  }
}

export default InvoiceFinancing;
